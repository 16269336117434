@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&family=Lato:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  background-color: #1a1a1a;
  color: #00ff00;
}

.container {
  text-align: center;
  padding: 30px;
  font-family: 'Press Start 2P', cursive;
  color: white;
  background-color: #1a1a1a;
}

h1 {
  font-size: 5rem;
  margin-bottom: 20px;
  color: limegreen;
  letter-spacing: 2px;
}

.tagline {
  font-size: 1.3rem;
  margin-bottom: 40px;
  color: #ccc;
}

.button {
  background-color: limegreen;
  color: black;
  text-decoration: none;
  padding: 10px;
  border: 2px solid black;
  font-family: 'Press Start 2P', cursive;
  font-size: 1.1rem;
  transition: transform 0.1s;
}

.button:hover {
  background-color: white;
}