.services {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 80px 0 100px;
}

.service {
    background-color: #222;
    padding: 20px;
    border: 3px solid limegreen;
    width: 220px;
    text-align: center;
    box-shadow: 5px 5px 0 limegreen;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s, background-color 0.2s;
}

.service:hover {
    border-color: white;
    box-shadow: 5px 5px 0 white;
    transform: translate(-2px, -2px);
}

.service h2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: limegreen;
}

.service p {
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.team {
    margin: 40px 0 100px;
}

.team-member {
    background-color: #222;
    padding: 20px;
    border: 3px solid limegreen;
    text-align: left;
    box-shadow: 5px 5px 0 limegreen;
    display: flex;
    align-items: center;
    width: 50%;
    margin: 20px auto;
    transition: max-height 0.3s ease, padding 0.3s ease;
    max-height: 150px;
    overflow: hidden;
}

.team-member:hover {
    max-height: 400px;
    padding: 20px 20px 40px;
}

.team-info .more-info {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.4s ease;
    overflow: hidden;
}

.team-member:hover .more-info {
    max-height: 200px;
    opacity: 1;
}

.team-photo {
    width: auto;
    height: 100px;
    margin: 0 20px;
    object-fit: contain;
    transition: margin 0.3s ease, height 0.3s ease;
}

.team-member.left {
    flex-direction: row;
}

.team-member.right {
    flex-direction: row-reverse;
}

.team-member.right .team-info {
    text-align: right;
}

.team-member.right .team-header {
    justify-content: flex-end;
    gap: 10px;
}

.team-info {
    flex: 1;
}

.team-info h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: limegreen;
}

.team-info p {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.team-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.team-header h3 {
    margin: 0;
}

.social-links {
    display: flex;
    gap: 10px;
}

.social-links a {
    color: limegreen;
    font-size: 1.5rem;
    transition: color 0.2s;
}

.social-links a:hover {
    color: white;
}

.projects, .contact {
    margin: 40px 0;
}

.project {
    background-color: #222;
    padding: 20px;
    border: 3px solid limegreen;
    margin-bottom: 20px;
    text-align: center;
    box-shadow: 5px 5px 0 limegreen;
}

.project h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: limegreen;
}

.project p {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    border: 3px solid limegreen;
    background-color: #222;
    color: white;
    font-size: 1.1rem;
    font-family: 'Press Start 2P', cursive;
    transition: border-color 0.2s, background-color 0.2s;
}

.contact-form textarea {
    padding: 15px;
    height: 200px;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: white;
    background-color: #333;
}

.contact-form button {
    background-color: limegreen;
    color: black;
    text-decoration: none;
    padding: 10px;
    border: 2px solid black;
    font-family: 'Press Start 2P', cursive;
    font-size: 1.1rem;
    transition: transform 0.1s;
}

.contact-form button:hover {
    background-color: white;
}

.balu-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

.button, .balu-button {
    background-color: limegreen;
    color: black;
    text-decoration: none;
    padding: 10px 20px;
    border: 2px solid black;
    font-family: 'Press Start 2P', cursive;
    font-size: 1.1rem;
    transition: transform 0.1s, background-color 0.2s;
}

.button:hover, .balu-button:hover {
    background-color: white;
    transform: scale(1.05);
}